<template>
  <div>
    <div class="sub-submit">
      <h2>便/捷/高/效&ensp;&ensp;互/联/互/通</h2>
      <span>想更详细的了解我们吗？请点击</span>
      <a href="javascript:;" title="联系我们" @click="toFormSunmit()">联系我们</a>
    </div>
    <div class="nav-footer">
      <div class="wrapper" style="overflow:hidden">
        <div class="footerLeft">
          <h3>辽宁山水通途物联智能科技有限公司</h3>
          <dl>
            <dt><i class="el-icon-location-outline" /></dt><dd>地址：辽宁省沈阳市浑南区上深沟860号<span>国际软件园F8-B408</span></dd>
          </dl>
          <dl>
            <dt><i class="el-icon-phone-outline" /></dt><dd>热线：400-080-0988</dd>
          </dl>
        </div>
        <div class="footerMiddle">
          <dl><dt>网站首页</dt><dd><a href="https://co.shttwl.com/#/login" title="管理系统入口" target="_blank">管理系统入口</a></dd></dl>
          <dl><dt>产品详情</dt><dd><a href="/baseHome/platform" title="网络货运平台">网络货运平台</a><i>|</i><a href="/baseHome/appDownLoad" title="APP介绍及下载">APP介绍及下载</a></dd></dl>
          <dl><dt>增值服务</dt><dd><a href="/baseHome/invoicing" title="开票服务">开票服务</a><i>|</i><a href="/baseHome/valueAdded" title="增值服务">增值服务</a><i>|</i><a href="/baseHome/ourservice" title="我们的服务">我们的服务</a></dd></dl>
          <dl><dt>关于我们</dt><dd><a href="/baseHome/about" title="公司简介">公司简介</a><i>|</i><a href="/baseHome/culture" title="企业文化">企业文化</a><i>|</i><a href="/baseHome/honor" title="荣誉资质">荣誉资质</a><span><a href="/baseHome/cooperation" title="合作共赢">合作共赢</a><i>|</i><a href="/baseHome/joinus" title="加入我们">加入我们</a></span></dd></dl>
        </div>
        <div class="footerRight">
          <dl><dt><img src="../assets/images/f1.jpg" width="106"></dt><dd>山水物联</dd></dl>
          <dl style="margin-left:27px;"><dt><img src="../assets/images/appmm.jpg" width="106"></dt><dd>APP下载</dd></dl>
        </div>
      </div>

      <div class="copyRight"><span>版权所有©  辽宁山水通途物联智能科技有限公司&nbsp;&nbsp;|&nbsp;&nbsp;辽ICP备2021001264号-1</span></div>
    </div>

    <contractForm ref="contractForm" />

  </div>
</template>

<script>
import contractForm from './contractForm'

export default {
  name: 'Join',
  components: {
    contractForm
  },
  data() {
    return {
      list: [],
      number: 0
    }
  },
  created() {
  },
  methods: {
    toFormSunmit() {
      this.$nextTick(() => {
        this.$refs.contractForm.init()
      })
    }

  }

}
</script>

<style lang="scss">
.sub-submit{
  position: relative; height: 300px;background: url('../assets/images/sub-submitbg.jpg') no-repeat top center;
  h2{ position: absolute; top: 105px; left: 50%; margin-left: -600px; color: #333; font-size: 30px; font-weight: bold; letter-spacing:10px;}
  span{ position: absolute; top: 164px; left: 50%; margin-left: -600px; font-size: 18px; color: #999; letter-spacing: 2px;}
  a{ position: absolute; top: 123px; left: 50%; margin-left: 400px; color: #fff; display: block; font-size: 18px; background: #194c8d; line-height: 50px; width: 200px; text-align: center;transition: all 0.5s;}
  a:hover{ background: #006cdb;}
}
.nav-footer {
  background: url('../assets/images/fbg.jpg') no-repeat top center;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  .footerLeft{
    float: left;
    width: 455px;
    h3{ font-size: 20px; opacity: .9; font-weight: normal; padding:68px 0 52px;}
    dl{
      line-height: 30px; padding-bottom: 27px;
      dt{display: inline;font-size: 30px; vertical-align: middle; padding-right: 10px;}
      dd{
        display: inline;
        span{ display: block; padding-left: 83px;}
      }
    }

  }
  .footerMiddle{
    float: left;width: 340px; line-height: 44px; padding: 55px 0 37px 0;
    dl{ opacity: .9;
      dt{ display: inline; font-weight: bold;}
      dd{
        display: inline; padding-left:24px;
        i{padding:0 15px; color: #77818c; font-size: 12px;}
        span{ display: block; padding-left: 80px;}
        a{color: #fff;}
        a:hover{text-decoration: underline;}

      }
    }

  }
  .footerRight{
    float: right; text-align: center; font-size: 15px; padding:92px 0 0;
    dl{
      float: left;
      dt img{ border: #b5b9bd solid 1px; padding:10px;}
      dd{padding-top: 18px;}
    }

  }
  .copyRight{
    text-align: center;font-size: 14px; color:#b8babc; line-height: 67px; width: 100%; border-top: #212c39 solid 1px;
    span{ display: block;border-top: #030b14 solid 1px;}
  }
}
</style>
