<template>
  <div class="subnav-header" :class="{ 'nav-header-active': navStyleChang.menuIndex, 'nav-bg': bgChang }">
    <div class="wraper">
      <div class="logo" @click="backIndex" />
      <div class="nav-menu">
        <div class="nav-menu-item" :class="{ 'menu-item-active': !bgChang }">
          <a href="/index" class="menu-link">首页</a>
        </div>
        <div class="nav-menu-item" :class="{ 'menu-item-active': product }">
          <a class="menu-link hhhhh" href="/baseHome/platform">产品详情</a>
          <div class="item-children">
            <a href="/baseHome/platform">网络货运平台</a>
            <a href="/baseHome/appDownLoad">APP介绍及下载</a>
          </div>
        </div>
        <div class="nav-menu-item" :class="{ 'menu-item-active': service }">
          <a class="menu-link" href="/baseHome/invoicing">增值服务</a>
          <div class="item-children">
            <a href="/baseHome/invoicing">开票服务</a>
            <a href="/baseHome/valueAdded">增值服务</a>
            <a href="/baseHome/ourservice">我们的服务</a>
          </div>
        </div>
        <div class="nav-menu-item" :class="{ 'menu-item-active': company }">
          <!--  :class="{ 'on': bgChang }" -->
          <a class="menu-link" href="/baseHome/about">关于我们</a>
          <div class="item-children">
            <a href="/baseHome/about">公司简介</a>
            <a href="/baseHome/culture">企业文化</a>
            <a href="/baseHome/honor">荣誉资质</a>
            <a href="/baseHome/cooperation">合作共赢</a>
            <a href="/baseHome/joinus">加入我们</a>
          </div>
        </div>
        <div class="nav-menu-item">
          <a class="menu-link" href="https://co.shttwl.com/#/login" target="_blank">管理系统</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import event from './../views/event'
export default {
  props: {
    bgChang: Boolean
  },
  data() {
    return {
      company: false,
      product: false,
      news: false,
      service: false,
      navStyleChang: {
        menuIndex: false,
        imgUrl: 'logo.png'
      }
    }
  },
  mounted() {
    // 绑定自定义事件
    event.$on('onChangNavHeader', this.changNavStyle)
    this.handleNavBgChang()
  },
  methods: {
    // 返回首页
    backIndex() {
      this.$router.push('/index')
    },
    changNavStyle(slideIndex) {
      if (slideIndex.slideInEnd === 2 && slideIndex.slideInUp === 1) {
        this.navStyleChang.menuIndex = true
      } else {
        this.navStyleChang.menuIndex = false
      }
    },
    handleNavBgChang() {
      const path = this.$router.history.current.name
      if (
        path === 'About' ||
        path === 'Culture' ||
        path === 'Honor' ||
        path === 'Cooperation' ||
        path === 'Joinus'
      ) {
        this.company = true
      } else if (path === 'Operate' || path === 'Platform' || path === 'AppDownLoad') {
        this.product = true
      } else if (
        path === 'CompanyNews' ||
        path === 'IndustryNews' ||
        path === 'NewsContent' ||
        path === 'Policy'
      ) {
        this.company = true
      } else if (
        path === 'Car' ||
        path === 'Invoicing' ||
        path === 'ValueAdded' ||
        path === 'Ourservice' ||

        path === 'Insurance' ||
        path === 'Finance' ||
        path === 'Etc' ||
        path === 'TaxRaising'
      ) {
        this.service = true
      }
    }
  }
}
</script>

<style lang="scss">
.subnav-header {
  &.nav-bg {
    position:relative;
    // background-color: #1e376f;
  }
  width: 100%;
  height: 80px;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  // position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  &.nav-header-active {
    .nav-menu-item {
      .menu-link {
        transition: all 0.8s;
        color: #f00;
      }
    }
    .logo {
      transition: all 2s;
      background: url("/static/img/logo1.png") no-repeat;
    }
  }
  .wraper {
    width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    cursor: pointer;
    background: url("/static/img/logo1.png") no-repeat;
    width: 213px;
    height: 63px;
  }
  .nav-menu {
    display: flex;
    height: 100%;
    font-size: 18px;
    .nav-menu-item {
      position: relative;
      height: 100%;
      transition: all 0.5s;
      a {
        display: block;
      }
      &:hover {
        .menu-link {
          transition: all 0.0001s;
          color: #fff;
        }
        .item-children {
          transition: all 0.5s;
          opacity: 1;
          visibility: visible;
        }
      }
      .item-children {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 80px;
        z-index: 9999;

        .qrcode {
          position: absolute;
          &:hover {
            img {
              transition: all 0.5s;
              opacity: 1;
            }
          }
          img {
            opacity: 0;
            position: absolute;
            left: 110px;
            bottom: -60px;
          }
        }
        a {
          font-size: 16px;
          display: block;
          width: 122px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background-color: #0062db;
          border-top: 1px solid #2679e0;
          // border-bottom: 1px solid #2679e0;
          color: #fff;
          transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;
          &:hover {
            background-color: #035dcb;
          }
        }
      }
      &:hover {
        background-color: #0062db;
      }
      a {
        color: #414141;
      }
      .on{ color: #fff;}
      margin: 0 26px;
      width: 122px;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
    }

    .menu-item-active {
      // border-bottom: 1px solid #2679e0;
      background-color: #0062db;
      a {
        color: #fff;
      }
    }
  }
}
</style>
