<template>
  <div class="base-home">
    <!-- <nav-header :bg-chang="true" /> -->
    <SubHeader :bg-chang="true" />
    <router-view />
    <nav-footer />
  </div>
</template>

<script>
// import NavHeader from './../components/NavHeader.vue'
import SubHeader from './../components/SubHeader.vue'
import NavFooter from './../components/NavFooter.vue'
export default {
  components: {
    // NavHeader,
    SubHeader,
    NavFooter
  }
}
</script>

<style lang="scss">
// @import "../assets/css/reset.css";
body {font-family:  "Microsoft Yahei", Arial, "Helvetica Neue", Helvetica, sans-serif;color: #414141;line-height: 1;-webkit-text-size-adjust: none;  min-width:1200px; width:auto!important; width:1200px;_width:expression(document.documentElement.clientWidth<1200?"1200px":"auto"); margin:0 auto;}

.base-home {
  // background-color: #f7f8fa;
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    // padding-top: 101px;
  }
}
</style>
