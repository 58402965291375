<template>
  <!-- :close-on-click-modal="false" -->
  <common-dialog title="填写企业信息" :close-on-click-modal="false" :is-visible.sync="visible" width="800px" @dialogClosed="cannelSave">
    <div class="contractForm">
      <el-form ref="ruleForm" :model="formData" :rules="rules" label-width="300px">
        <el-form-item label="联系人：" prop="linkmanName">
          <el-input v-model="formData.linkmanName" style="width:300px" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话：" prop="phoneNumber">
          <el-input v-model="formData.phoneNumber" style="width:300px" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="发布货物名称：" prop="goodsName">
          <el-input v-model="formData.goodsName" style="width:300px" placeholder="请输入发布货物名称" />
        </el-form-item>
        <el-form-item label="数量(吨)：" prop="goodsWeight">
          <el-input v-model="formData.goodsWeight" style="width:300px" placeholder="请输入数量(吨)" type="number" />
        </el-form-item>
        <el-form-item label="运距(公里)：" prop="distance">
          <el-input v-model="formData.distance" style="width:300px" placeholder="请输入运距(公里)" type="number" />
        </el-form-item>
        <el-form-item label="发货地：" prop="areaId">
          <el-cascader ref="myCascader" :options="list" clearable :props="countryObj" style="width:300px" placeholder="请选择发货地" filterable @change="proviceChange" @getCheckedNodes="Nodes" />
        </el-form-item>
        <el-form-item label="是否是一般纳税人：" prop="isTaxpayer">
          <el-radio-group v-model="formData.isTaxpayer">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否使用过网络货运平台：" prop="isPlatformUser">
          <el-radio-group v-model="formData.isPlatformUser">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <div style="margin:50px 0 0 0; text-align: center;">
        <span class="buttons" @click="submit('ruleForm')">确 定</span>
        <span class="buttons" style="border:#dcdfe6 solid 1px; background:#fff; color:#414141; margin-left:20px;" @click="cannelSave">取 消</span>
      </div>
    </div>

  </common-dialog>

</template>

<script>
export default {
  name: 'ContractForm',
  data() {
    return {
      visible: false,
      jobInfo: {},
      list: [],
      countryObj: {
        value: 'key',
        label: 'value', 		// 字段对应的文字
        children: 'childList',
        expandTrigger: 'click'
        // emitPath: true
        // checkStrictly: true
      },
      formData: {
        linkmanName: '', // 联系人
        phoneNumber: '', // 联系电话
        enterpriseName: '', // 企业名称
        goodsName: '', // 发布货物名称
        goodsWeight: '', // 数量
        distance: '', // 距离
        areaId: '', // 地址ID
        merName: '', // 地址全称
        isTaxpayer: '', // 是否为一般纳税人(true是false不是)
        isPlatformUser: '' // 是否使用过网络货运平台(true是false不是)
      },
      rules: {
        linkmanName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phoneNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        goodsName: [{ required: true, message: '请输入货物名称', trigger: 'blur' }],
        goodsWeight: [{ required: true, message: '请输入数量(吨)', trigger: 'blur' }],
        distance: [{ required: true, message: '请输入运距(公里)', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择发货地', trigger: 'blur' }],
        isTaxpayer: [{ required: true, message: '请选择是否为一般纳税人', trigger: 'blur' }],
        isPlatformUser: [{ required: true, message: '请选择是否使用过网络货运平台', trigger: 'blur' }]
      }
    }
  },
  created() {

  },
  methods: {
    init() {
      this.visible = true
      this.initAddressArea()
    },
    initAddressArea() {
      this.$http.post('api/ContactUs/GetAreaComboBox', {}).then(res => {
        this.list = res.data
        console.log(this.list, '返回来的下拉内容')
      }).catch(error => { console.log(error) })
    },
    Nodes(data) {
      console.log(data, '3333333333333333333333')
    },
    proviceChange(data, arr) {
      this.formData.areaId = data[0] + ',' + data[1] + ',' + data[2]
      this.formData.merName = (this.$refs.myCascader.getCheckedNodes()[0].pathLabels).toString()
      // console.log(this.formData.merName, '0000000000000000000')
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetFormData() {
      // this.$refs.ruleForm.resetFields()
      this.formData = {
        linkmanName: '', // 联系人
        phoneNumber: '', // 联系电话
        enterpriseName: '', // 企业名称
        goodsName: '', // 发布货物名称
        goodsWeight: '', // 数量
        distance: '', // 距离
        areaId: '', // 地址ID
        merName: '', // 地址全称
        isTaxpayer: '', // 是否为一般纳税人(true是false不是)
        isPlatformUser: '' // 是否使用过网络货运平台(true是false不是)
      }
    },
    save() {
      this.$http.post('api/ContactUs/Add', this.formData).then(res => {
        if (res.code === 1) {
          // this.$message({ showClose: true, type: 'success', message: '提交成功！我们会尽快联系您！', duration: '8000' })
          this.$alert('我们会在3个工作日内联系您！', '提交成功', {
            confirmButtonText: '关闭'
            // callback: action => {
            //   this.$message({
            //     type: 'info',
            //     message: `action: ${action}`
            //   })
            // }
          })

          setTimeout(() => {
            this.resetFormData()
          }, 0)
          this.visible = false
        }
      }).catch(error => { console.log(error) })
    },
    //
    cannelSave() {
      this.visible = false
      this.resetFormData()
    }
  }
}
</script>

<style lang="scss">
.contractForm {
  padding: 30px 0 50px; border-top: 1px solid rgb(232, 232, 232);
  .buttons{background: #0062db; color: #fff;border-radius: 4px; font-size: 16px; text-align: center; cursor: pointer; padding:8px 26px;}
  .buttons:hover{ opacity: .8;}

}
.el-radio__inner {
    border: 1px solid #DCDFE6;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    background-color: #FFF;
    cursor: pointer;
    box-sizing: border-box;
}
</style>
